import './App.scss';
import {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Container, Row, Table} from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import {fetchUrl} from "./constants/constants";

function App() {
    const [mods, setMods] = useState([])
    const [versions, setVersions] = useState([])
    const [sortState, setSortState] = useState("name");

    const releaseTypes = [
        'release', 'beta', 'alpha'
    ]

    useEffect(() => {
        axios.get(fetchUrl + 'versions/')
            .then(res => {
                setVersions(res.data)
            })
    }, []);

    const versionChange = (event) => {
        axios.get(fetchUrl + 'overview/' + event.target.value + '/')
            .then(res => {
                setMods(res.data)
            })
    }

    const sortMethods = {
        name: {
            method: (a, b) => {
                return a.mod.name > b.mod.name ? 1 : -1
            }
        },
        release: {
            method: (a, b) => {
                if (!a.release_file && !b.release_file) return -1;
                if (a.release_file && !b.release_file) return -1;
                if (!a.release_file && b.release_file) return 1;
                return a.release_file.date > b.release_file.date ? -1 : 1
            }
        },
        beta: {
            method: (a, b) => {
                if (!a.beta_file && !b.beta_file) return -1;
                if (a.beta_file && !b.beta_file) return -1;
                if (!a.beta_file && b.beta_file) return 1;
                return a.beta_file.date > b.beta_file.date ? -1 : 1
            }
        },
        alpha: {
            method: (a, b) => {
                if (!a.alpha_file && !b.alpha_file) return -1;
                if (a.alpha_file && !b.alpha_file) return -1;
                if (!a.alpha_file && b.alpha_file) return 1;
                return a.alpha_file.date > b.alpha_file.date ? -1 : 1
            }
        },
        Drelease: {
            method: (a, b) => {
                if (!a.release_file && !b.release_file) return -1;
                if (a.release_file && !b.release_file) return -1;
                if (!a.release_file && b.release_file) return 1;
                return a.release_file.download_date > b.release_file.download_date ? -1 : 1
            }
        },
        Dbeta: {
            method: (a, b) => {
                if (!a.beta_file && !b.beta_file) return -1;
                if (a.beta_file && !b.beta_file) return -1;
                if (!a.beta_file && b.beta_file) return 1;
                return a.beta_file.download_date > b.beta_file.download_date ? -1 : 1
            }
        },
        Dalpha: {
            method: (a, b) => {
                if (!a.alpha_file && !b.alpha_file) return -1;
                if (a.alpha_file && !b.alpha_file) return -1;
                if (!a.alpha_file && b.alpha_file) return 1;
                return a.alpha_file.download_date > b.alpha_file.download_date ? -1 : 1
            }
        }
    }

    const sortChange = (rt) => {
        setSortState(rt);
    }

    return (
        <Container fluid className={'px-3'}>
            <Row className={'mb-5 mt-3'}>
                <Col>
                    <select className="form-control" name="version" onChange={versionChange}>
                        {
                            versions && versions.map((v) => (
                                <option key={v.name} value={v.name}>{v.name}</option>
                            ))
                        }
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered>
                        <thead>
                        <tr>
                            <th></th>
                            <th>
                                Mod
                                <Button size={'sm'} variant={'outline-primary'} type={'button'} onClick={() => sortChange('name')}>&#x2197;</Button>
                            </th>
                            {
                                releaseTypes.map((rt) => (
                                    <th key={rt}>
                                        {rt}
                                        <Button size={'sm'} variant={'outline-primary'} type={'button'} onClick={() => sortChange(rt)}>R &#x2198;</Button>
                                        <Button size={'sm'} variant={'outline-primary'} type={'button'} onClick={() => sortChange('D' + rt)}>D &#x2198;</Button>
                                    </th>
                                ))
                            }
                        </tr>
                        </thead>
                        {
                            mods && mods.sort(sortMethods[sortState].method).map((m) => (
                                <tbody>
                                <tr>
                                    <td style={{verticalAlign: 'middle', textAlign: 'center'}} rowSpan="2">
                                        {
                                            m.in_use && <h2>X</h2>
                                        }
                                    </td>
                                    <td>
                                        <a href={m.mod.modUrl} target="_blank" rel="noreferrer">{m.mod.name}</a>
                                    </td>
                                    {
                                        releaseTypes.map((rt) => (
                                            m[rt + '_file'] ? (
                                                <td key={rt}>
                                                    <div>
                                                        <div className={'rt-block rt-' + rt}>{rt[0].toUpperCase()}</div>
                                                        <a href={m[rt + '_file'].downloadUrl} target="_blank" rel="noreferrer" className={'file_name'}>{m[rt + '_file'].filename}</a>
                                                        <div>
                                                            {
                                                                m[rt + '_file'].versions.map((m) => (
                                                                    <span>{m.name}|</span>
                                                                ))
                                                            }
                                                        </div>
                                                        <div>R: {moment(m[rt + '_file'].date).format('D. M. yyyy H:mm')}</div>
                                                        <div>DL: {moment(m[rt + '_file'].download_date).format('D. M. yyyy H:mm')}</div>
                                                    </div>
                                                </td>
                                            ) : <td>----</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td colSpan="4">{m.mod.summary}</td>
                                </tr>
                                </tbody>
                            ))
                        }
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
